<template>
	<v-dialog v-model="dialog" width="auto" fullscreen transition="slide-fade" hide-overlay light>
		<v-row class="d-flex align-content-end justify-end pr-12" style="padding-top: 120px">
			<v-card light :width="options.width" :style="{ zIndex: options.zIndex }" class="mobile-filter-view" @keydown.esc="cancel" :key="componentKey">
				<v-toolbar class="d-flex flex-column" :color="options.color" dense flat>
					<v-toolbar-title style="color: #212121">{{ title }}</v-toolbar-title>
					<div class="ml-auto close-filter" v-on:click="dialog = false"><img :src="$store.state.icons.icons['RedCross']" alt /></div>
				</v-toolbar>
				<hr style="border-color: #e7eaf3; border-style: solid" />
				<v-card-text class="pa-4">
					<v-checkbox class="custome-check mt-0" color="info" hide-details :ripple="false" v-model="filter_by.new_list" label="New List"></v-checkbox>
					<v-checkbox class="custome-check" color="info" hide-details :ripple="false" v-model="filter_by.active" label="Active"></v-checkbox>
					<v-checkbox class="custome-check" color="info" hide-details :ripple="false" v-model="filter_by.expired" label="Expired"></v-checkbox>
					<v-checkbox class="custome-check" color="info" hide-details :ripple="false" v-model="filter_by.rejected" label="Rejected"></v-checkbox>
					<v-checkbox class="custome-check" color="info" hide-details :ripple="false" v-model="filter_by.loss_bid" label="Loss Quote"></v-checkbox>
					<v-checkbox class="custome-check" color="info" hide-details :ripple="false" v-model="filter_by.accepted" label="Accepted"></v-checkbox>
				</v-card-text>
				<hr style="border-color: #e7eaf3; border-style: solid" />
				<v-card-actions class="pa-3">
					<v-spacer></v-spacer>
					<v-btn outlined class="my-outline-btn" @click.native="agree">Filter</v-btn>
					<v-btn outlined class="my-outline-btn" @click.native="cancel">Clear All</v-btn>
				</v-card-actions>
			</v-card>
		</v-row>
	</v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>close-filter
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
	data: () => ({
		componentKey: 0,
		dialog: false,
		filter_by: {},
		title: null,
		options: {
			color: '#FFFFFF',
			width: 250,
			zIndex: 200,
		},
	}),

	methods: {
		open(title, filter_by, options) {
			this.dialog = true
			this.title = title
			this.filter_by = Object.assign(this.filter_by, filter_by)
			this.options = Object.assign(this.options, options)
			return this.filter_by
		},

		agree() {
			this.$emit('refreshFilter', this.filter_by)
			this.dialog = false
		},

		cancel() {
			this.filter_by.new_list = false
			this.filter_by.active = false
			this.filter_by.expired = false
			this.filter_by.rejected = false
			this.filter_by.accepted = false
			this.filter_by.loss_bid = false

			this.componentKey += 1
			this.$emit('refreshFilter', this.filter_by)
			this.dialog = false
		},
	},
}
</script>

<style lang="scss">
.close-filter {
	cursor: pointer;
	img {
		height: 16px;
		filter: brightness(0) saturate(100%) invert(17%) sepia(1%) saturate(2954%) hue-rotate(9deg) brightness(107%) contrast(80%);
	}
}
@media only screen and (max-width: 600px) {
	.mobile-filter-view {
		bottom: -198px;
		right: -20px;
	}
}
</style>
